<template>
  <div class="toggle">
    <div class="toggle-header" @click="toggle">
      <slot name="header"></slot>
      <img alt="V" src="@/assets/dropdown.svg" class="expand-icon"
           :class="{'spin': show }">
    </div>
    <div class="toggle-content">
<!--      <transition-->
<!--        name="custom"-->
<!--        enter-active-class="animate__animated animate__backInRight animate__faster"-->
<!--        leave-active-class="animate__animated animate__backOutRight animate__faster"-->
<!--      >-->
        <slot v-if="show"></slot>
<!--      </transition>-->
    </div>

  </div>
</template>

<script>

export default {
  name: 'Toggle',
  data: () => ({
    show: false
  }),
  methods: {
    toggle: function () {
      this.show = !this.show
    }
  }
}
</script>

<style scoped>
.toggle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.toggle-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  /*justify-content: center;*/
}

.toggle-content {
  display: flex;
  /*justify-content: center;*/
}

.expand-icon {
  height: 20px;
  margin-left: 12px;
}

.spin {
  animation: spin 0.2s linear;
  animation-fill-mode: forwards;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(180deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

</style>
