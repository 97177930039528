<template>
  <div class="team-container">
    <div class="page-title">
      <h1>L'équipe</h1>
    </div>
    <Block>
      <div class="presentation-container" v-for="user in teams" :key="user.name">
        <div class="presentation-header">
          <img alt="Victor" :src="user.image" class="presentation-photo">
        </div>
        <div class="presentation-description">
          <div class="presentation-header-info">
            <h2 class="presentation-header-info-name">{{ user.name }}</h2>
            <div>{{ user.role }}</div>
          </div>
          <Toggle v-if="user.description">
            <template v-slot:header>
              <div>
                <h3>Description</h3>
              </div>
            </template>
            <div>{{ user.description }}</div>
          </Toggle>
          <Toggle v-if="user.formation">
            <template v-slot:header>
              <div>
                <h3>Formations et diplômes </h3>
              </div>
            </template>
            <div>
              <ul>
                <li v-for="formation in user.formation.list" :key="formation">{{ formation }}</li>
              </ul>
              <div v-for="child in user.formation.child" :key="child.title" class="formation-child">
                <Toggle>
                  <template v-slot:header>
                    <h4>{{ child.title }}</h4>
                  </template>
                  <ul>
                    <li v-for="item in child.list" :key="item">{{ item }}</li>
                  </ul>
                </Toggle>
              </div>
            </div>
          </Toggle>
          <Toggle v-if="user.experiences">
            <template v-slot:header>
              <div>
                <h3>Expérience professionnelle</h3>
              </div>
            </template>
            <ul>
              <li v-for="experience in user.experiences.list" :key="experience">{{ experience }}</li>
            </ul>
          </Toggle>

          <div class="title">
            <h3>Langues parlées</h3>
          </div>
          <div class="title">
            {{ user.languages.join(', ') }}
          </div>
          <div v-if="user.contact">
            <div class="title">
              <h3>Contact</h3>
            </div>
            <div>
              <div class="contact" v-if="user.contact.number">
                <a :href="'tel:'+user.contact.number">{{ user.contact.number }}</a>
              </div>
              <div class="contact" v-if=" user.contact.email">
                <a :href="'mailto:'+ user.contact.email">{{ user.contact.email }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Block>
  </div>
</template>

<script>
import Block, { BLOCK_SVG } from '@/components/Block'
import Toggle from '@/components/Toggle'

export default {
  name: 'Equipe',
  components: {
    Toggle,
    Block
  },
  data: () => ({
    BLOCK_SVG,
    teams: [
      {
        name: 'Sofia Da Silva Cardoso',
        image: require('@/assets/team/sofia.jpg'),
        role: 'Physiothérapeute co-fondatrice',
        formation: {
          list: [
            'Master en Thérapie Manuelle Orthopédique, Université de Saragosse, Espagne (2016)',
            'Bachelor of Sciences en Physiothérapie à la HECVSanté, Lausanne (2011)',
            'Raisonnement clinique dans les pathologies d\'épaule, Barcelone (2021)',
            'Formation en crochetage myofascial (méthode Kurt-Ekmann)',
            'Formation Réeducation de la cicatrice après une brûlure grave',
            'Neurodynamique Clinique : applications cliniques au membre inférieur',
            'Formation CefarCompex',
            'Spécialiste en prévention des blessures en course à pied - La Clinique du Coureur',
            'Formation Gainage du pied'
          ],
          child: [
            {
              title: 'Pédiatrie',
              list: [
                'Diplôme de Rééducation en pelvi-périnéologie adulte et enfants + Cours de perfectionnement en rééducation pelvipérinéologique pédiatrique (2011; 2017; 2023)',
                'Physiothérapie respiratoire pédiatrique (2022)',
                'Plagiocéphalie : évaluation, prises de mesures, intervention, collaboration interprofessionnelle et liens avec la sphère orale du bébé (2022)',
                'Évaluation et traitement des asymétries d\'alignement des membres inférieurs chez les enfants (2022)',
                'Physiothérapie et les coliques du nourrisson, Barcelone (2021)',
                'Formation « Physiothérapie / Ostéopathie pédiatrique » : Thérapie manuelle cranio sacrée, levée de tensions (chaines musculaires, chaine viscérale, ...)',
                'Formation malpositions des pieds des nouveaux-nés',
                'Formation en kinésio taping, spécialisation en pédiatrie',
                'Évaluation et traitement des scolioses idiopathiques de l’enfant et de l’adolescent / formation le rachis de l\'enfant',
                'Chargée de formation concernant le Membre inférieur de l\'enfant, orthopédie et traumatologie',
                'Formation chez le petit enfant',
                'Torticolis chez le nourrisson',
                'Pilates pour les enfants'
              ]
            },
            {
              title: 'Suivi de grossesse et post-partum',
              list: [
                'L\'entraînement fonctionnel des muscles du plancher pelvien - le diastasis des grands droits (2020; 2023)',
                'Kinésithérapie globale en pré & post partum',
                'Congrès Sport & Périnée',
                'Low Pressure Fitness: Gymnastique Hypopressive',
                'Formation « Approche manuelle de la région lombo-pelvienne »',
                'Formation gainage Complexe Core certificate'
              ]
            }
          ]
        },
        experiences: {
          list: [
            'Intervenante à la Journée Scientifique Physiovaud 2022 avec « Sport et Périnatalité, entre mythes et croyances » (2022)',
            'Physiothérapeute indépendante (co-fondatrice) à Activ\'Physio, Gland (2015-2021)',
            'Physiothérapeute à l\'Hôpital de l\'Enfance, CHUV, Lausanne (2011-2015)',
            'Assistante de cours à la Haute École Santé Vaud en Physiothérapie, Lausanne (2015)',
            'Chargée de cours à la Haute École Santé Vaud en Physiothérapie, Lausanne (2016-2017)',
            'Physiothérapeute assistante LUC Volleyball Lausanne (2010-2011)',
            'Instructrice / chorégraphe / danseuse (Dancehall; Zumba)',
            'Instructrice en Gymnastique Hypopressive'
          ]
        },
        languages: ['Français', 'portugais', 'espagnol', 'anglais']
      },
      {
        name: 'Victor Lopez Vegas',
        image: require('@/assets/team/victor.jpg'),
        role: 'Physiothérapeute co-fondateur',
        formation: {
          list: [
            'Master en Thérapie Manuelle Orthopédique, Université de Saragosse, Espagne (2013)',
            'Master en physiothérapie appliquée à l\'activité physique et au sport (2007)',
            'Bachelor of Sciences en Physiothérapie (2003)',
            'Expert en échographie musculo-squelettique (2022)',
            'EPI (Electrolyse Percutanée Intra-tissulaire) et neuromodulation avancée (2022)',
            'Electrolyses percutanée thérapeutique, Santiago (2021)',
            'Neuromodulation Percutanée, Madrid (2021)',
            'Raisonnement clinique dans les pathologies d\'épaule, Barcelone (2021)',
            'Physiothérapie et les coliques du nourrisson, Barcelone (2021)',
            'Traitement avancé des tendinopathies membre inferieur, Alicante (2020)',
            'Thérapie manuelle des céphalées, Lyon (2019)',
            'Echographie musculo-squelettique, Madrid (2019).'
          ],
          child: [
            {
              title: 'Therapie Manuelle',
              list: [
                'Plagiocéphalie : évaluation, prises de mesures, intervention, collaboration interprofessionnelle et liens avec la sphère orale du bébé (2022)',
                'Prise en charge des dysfonctions cervicales et crânio-mandibulaires (2017)',
                'Évaluation et traitement des scolioses idiopathiques de l’enfant et de l’adolescent / formation le rachis de l\'enfant (2017)',
                'Neurodynamique Clinique : applications cliniques au membre inférieur (2015)',
                'Formation en crochetage myofascial (méthode Kurt-Ekmann) (2014)',
                'Évaluation et traitement des tissus mous (2014)',
                'Manipulations en Thérapie Manuelle Orthopédique Cervicale Avancée. (2011)',
                'Formation en Dry needling (puncture sèche) (2010)',
                'Concept Mulligan. (2010)',
                'Torticolis chez le nourrisson',
                'Plagiocéphalie : évaluation, prises de mesures, intervention, collaboration interprofessionnelle et liens avec la sphère orale du bébé (2022)'
              ]
            },
            {
              title: 'Sport',
              list: [
                'Low Pressure Fitness: Gymnastique Hypopressive (2016)',
                'Check Your Motion : évaluation et correction des troubles du mouvement (2016)',
                'Spécialiste en prévention des blessures en course à pied - La Clinique du Coureur (2016)'
              ]
            }
          ]
        },
        experiences: {
          list: [
            'Physiothérapeute indépendant à Activ\'Physio, Gland (2018-2021)',
            'Physiothérapeute pour l\'équipe motrice Seven 7 (2006-2008)',
            'Physiothérapie au Sports Club Seven 7(2005-2009)',
            'Physiothérapeute au Club Baloncesto Atapuerca, pendant 6 saisons, en compétition dans la ligue LEB ORO (Deuxième ligue d’ Espagne). Burgos (2009-2015)',
            'Kinésithérapeute Basketball Club Miraflores participant à la ligue LEB ORO. Burgos. (2015-2016)',
            'Physiothérapeute à temps partiel CF Burgos participant à la deuxième division B de la ligue nationale espagnole (2015-2016)',
            'Physiothérapeute indépendant à Burgos. (2009-2016)',
            'Assistant de cours au Master en Thérapie Manuelle Orthopédique. Zaragoza. (2012-2015)',
            'Physiothérapeute à Kencencentre à Nyon. Suisse.(20016-2018)',
            'Instructeur en Gymnastique Hypopressive'
          ]
        },
        languages: ['Français', 'espagnol', 'anglais']
      },
      {
        name: 'Marie Steiner',
        image: require('@/assets/team/marieSteiner.png'),
        role: 'Physiothérapeute',
        formation: {
          list: [
            'Bachelor en Physiothérapie, Allemagne (2021)',
            'Drainage lymphatique à la Földischule à Fribourg (2021)',
            'Premier secours donné par les intervenants « Malteser Hilfsdienst »',
            'Cours de Taping à la PSO (axé sur les blessures des sportifs), Allemagne (2021)',
            'Certificat Kinésithérapie du Sport Expert (2024)'
          ]
        },
        experiences: {
          list: [
            'Physiothérapeute en Allemagne dans 2 cabinets axés sur les troubles orthopédiques, neurologiques et les sportifs: Gesundheitspraxis Sauvagnat & Physioteam Berghaupten'
          ]
        },
        languages: ['Français', 'allemand', 'anglais'],
        contact: {
          number: '+41 78 267 16 75',
          email: 'marie.steiner@svphysio.ch'
        }
      },
      {
        name: 'Marion Chopard',
        image: require('@/assets/team/MarionChopard.jpeg'),
        role: 'Physiothérapeute',
        formation: {
          list: [
            'Chaînes musculaires Busquet (8 modules) - 2019 et 2020',
            'Kinésithérapie et cancer du sein - 2023',
            'Concept Globale de l\'Épaule (CGE) - 2018',
            'Formation École du Dos Mail14 - 2017',
            'Kinésithérapie respiratoire du nourrisson de O à 2 ans - 2015'
          ],
          child: [
            {
              title: 'Suivi de grossesse et post-partum',
              list: [
                'Pilates thérapeutique et rééducation lombo-abdomino-pelvienne – 2023',
                'Pelvi-périnéologie féminine-Les fondamentaux - 2022',
                'Yoga sans dégâts De Gasquet – 2021'
              ]
            }
          ]

        },
        experiences: {
          list: [
            'Physiothérapeute à Physiothérapie du Mont d’or, Suisse (2019-2024)',
            'Assistante Collaborateur (Labergement- Sainte-Marie, France) (Octobre 2017 - Décembre 2018)',
            'Physiothérapeute au sein de cabinet libéral en Haute-Savoie, Franche-Comté, Guadeloupe, Tie de la Réunion (2015-2017)',
            'Assistante kinésithérapeute en cabinet libéral dans une maison pluridisciplinaire (La Cluse et Mijoux, France) (Novembre 2014 - Mars 2015)',
            'Physiothérapeute au sein de cabinet libéral à Languedoc-Roussillon, Provence Alpes Cotes d\'Azur, Haute-Corse et au centre thermal de Dax-les-Bains, Aquitaine (2014)'
          ]
        },
        languages: ['Français'],
        contact: {
          email: 'marion.chopard@svphysio.ch'
        }
      },
      {
        name: 'Maëva Blonde',
        image: require('@/assets/team/maeva_blonde.jpg'),
        role: 'Physiothérapeute',
        formation: {
          list: [
            'Spécialisée en rééducation ano rectal (2023)',
            'Spécialisée en douleurs pelviennes et troubles sexologiques (2022)',
            'Spécialisée en rééducation périnéale féminine (2021)',
            'Spécialisée en sénologie / cancer du sein (2021)',
            'Spécialisée en traitement des cicatrices et drainage lymphatique (2021)',
            'Master en physiothérapie (2017-2021)',
            'PACES Médecine (2015-2017)'
          ]
        },
        experiences: {
          list: [
            'Cabinet Arnone NEUCHÂTEL - Responsable équipe physiothérapeute (2023)',
            'Cabinet Arnone NEUCHÂTEL - Physiothérapeute (2021-2024)'
          ]
        },
        languages: ['Français', 'Anglais B2', 'Espagnol scolaire']
      },
      {
        name: 'Chloé Zybach',
        image: require('@/assets/team/ChloeZybach.png'),
        role: 'Personal Trainer',
        description: 'Tu cherches une coach à l’écoute et bienveillante pour t’aider à remplir tes objectifs dans un lieu accueillant et sans jugement ? Alors tu es au bon endroit. En t’entrainant à mes côtés, tu apprendras à aimer ton corps et à l’amener au meilleur de son potentiel en passant un moment agréable. Mon but est de te motiver à donner le maximum de toi-même et de te voir progresser et prendre de l’assurance. N’attends pas demain et fais-moi confiance pour te faire transpirer, avec le sourire !',
        languages: ['Français', 'italien', 'anglais'],
        contact: {
          number: '+41 78 737 83 85',
          email: 'fwc.trainer@gmail.com'
        }
      }
    ]
  })
}
</script>

<style scoped>
.team-container {
  background: url("../assets/team/background/bg1.jpg") no-repeat top;
  background-size: 100% auto;
}

.presentation-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
  background-color: #FFFFFFDF;
  border: 1px solid var(--secondary-color-light);
  border-radius: 24px;
  padding: 24px;
}

.presentation-container + .presentation-container {
  margin-top: 80px;
}

.presentation-header {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.presentation-header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.presentation-header img {
  margin: 0 60px;
}

.presentation-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.presentation-photo {
  height: 400px;
  border-radius: 12px;
}

.presentation-photo + .presentation-description {
}

.reverse {
  flex-direction: row-reverse;
  text-align: end;
}

.formation-child {
  margin-left: 30px;
}

.presentation-header-info {
  color: var(--primary-color);
  font-size: x-large;
}

h2 {
  margin: 10px 0;
}

.title {
}

.contact + .contact {
  margin-top: 7px;
}

.contact a {
  color: var(--primary-color);
}

@media (max-width: 961px) {
  .team-container {
    background-size: auto;
  }
}

@media (max-width: 641px) {
  .presentation-container {
    flex-direction: column;
  }
}
</style>
